exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-become-instructor-js": () => import("./../../../src/pages/become-instructor.js" /* webpackChunkName: "component---src-pages-become-instructor-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-details-js": () => import("./../../../src/pages/course-details.js" /* webpackChunkName: "component---src-pages-course-details-js" */),
  "component---src-pages-course-four-js": () => import("./../../../src/pages/courseFour.js" /* webpackChunkName: "component---src-pages-course-four-js" */),
  "component---src-pages-course-js": () => import("./../../../src/pages/course.js" /* webpackChunkName: "component---src-pages-course-js" */),
  "component---src-pages-course-three-js": () => import("./../../../src/pages/courseThree.js" /* webpackChunkName: "component---src-pages-course-three-js" */),
  "component---src-pages-course-two-js": () => import("./../../../src/pages/courseTwo.js" /* webpackChunkName: "component---src-pages-course-two-js" */),
  "component---src-pages-event-details-js": () => import("./../../../src/pages/event-details.js" /* webpackChunkName: "component---src-pages-event-details-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-faq-details-js": () => import("./../../../src/pages/faq-details.js" /* webpackChunkName: "component---src-pages-faq-details-js" */),
  "component---src-pages-faq-page-js": () => import("./../../../src/pages/faq-page.js" /* webpackChunkName: "component---src-pages-faq-page-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-home-three-js": () => import("./../../../src/pages/homeThree.js" /* webpackChunkName: "component---src-pages-home-three-js" */),
  "component---src-pages-home-two-js": () => import("./../../../src/pages/homeTwo.js" /* webpackChunkName: "component---src-pages-home-two-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructor-js": () => import("./../../../src/pages/instructor.js" /* webpackChunkName: "component---src-pages-instructor-js" */),
  "component---src-pages-instructor-profile-js": () => import("./../../../src/pages/instructor-profile.js" /* webpackChunkName: "component---src-pages-instructor-profile-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-membership-js": () => import("./../../../src/pages/membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-shop-details-js": () => import("./../../../src/pages/shop-details.js" /* webpackChunkName: "component---src-pages-shop-details-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-student-profile-js": () => import("./../../../src/pages/student-profile.js" /* webpackChunkName: "component---src-pages-student-profile-js" */),
  "component---src-pages-verify-email-js": () => import("./../../../src/pages/verify-email.js" /* webpackChunkName: "component---src-pages-verify-email-js" */),
  "component---src-pages-webinar-details-js": () => import("./../../../src/pages/webinar-details.js" /* webpackChunkName: "component---src-pages-webinar-details-js" */),
  "component---src-pages-webinars-js": () => import("./../../../src/pages/webinars.js" /* webpackChunkName: "component---src-pages-webinars-js" */),
  "component---src-pages-wishlist-js": () => import("./../../../src/pages/wishlist.js" /* webpackChunkName: "component---src-pages-wishlist-js" */),
  "component---src-pages-zoom-class-details-js": () => import("./../../../src/pages/zoom-class-details.js" /* webpackChunkName: "component---src-pages-zoom-class-details-js" */),
  "component---src-pages-zoom-class-js": () => import("./../../../src/pages/zoom-class.js" /* webpackChunkName: "component---src-pages-zoom-class-js" */)
}

